<template>
  <div class="content__body">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">Список заказов</h1>
    </div>
    <div class="content__title content__title--party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <div class="filters filters--indent filters--between">
      <div class="filters__block">
        <input
          @change="fetchOrders(1, limitSelected.name, status)"
          id="status1"
          type="radio"
          value=""
          v-model="status"
          checked
        >
        <label for="status1" class="filters__item">Все</label>
        <input
          @change="fetchOrders(1, limitSelected.name, status)"
          id="status2"
          type="radio"
          value="0"
          v-model="status"
        >
        <label for="status2" class="filters__item">Успешных</label>
        <input
          @change="fetchOrders(1, limitSelected.name, status)"
          id="status3"
          type="radio"
          value="1"
          v-model="status"
        >
        <label for="status3" class="filters__item">Требует внимания</label>
        <input
          @change="fetchOrders(1, limitSelected.name, status)"
          id="status4"
          type="radio"
          value="2"
          v-model="status"
        >
        <label for="status4" class="filters__item">Ошибка</label>
      </div>
      <div class="filters__search">
        <v-select
          @search="onSearch"
          :get-option-label="getLabel"
          noDrop
          placeholder="Code 1C / ФИО"
          class="select"
        >
        </v-select>
      </div>
    </div>
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="ordersListTable"
      class="table-default table-default--dynamic">
      <template slot="codes_1c" slot-scope="option">
        <div v-for="code in option.row.codes_1c" :key="code">{{ code }}</div>
      </template>
      <template slot="created_at" slot-scope="option">
        {{ toNormalDateTime(option.row.created_at) }}
      </template>
      <template slot="client" slot-scope="option">{{ option.row.client.name }} ({{ option.row.client.id }})</template>
      <template slot="counterparty" slot-scope="option">{{ option.row.counterparty.name }} ({{ option.row.counterparty.code_1c }})</template>
      <template slot="user" slot-scope="option">
        {{ option.row.user ? `${option.row.user.fio} (${option.row.user.id})` : 'Пользователь удален' }}
      </template>
      <template slot="status" slot-scope="option">
        {{ option.row.status === 0 ? 'Успешно' : option.row.status === 1 ? 'Требует внимания' : 'Ошибка' }}
      </template>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns'
import { debounce } from 'lodash'

export default {
  name: 'OrdersList',
  components: { dropdown },
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'codes_1c', 'created_at', 'total_sum', 'client', 'counterparty', 'user', 'status'],
        options: {
          headings: {
            'id': 'ID',
            'codes_1c': 'Номер заказа 1С',
            'created_at': 'Дата',
            'total_sum': 'Сумма',
            'client': 'Клиент',
            'counterparty': 'Контрагент',
            'user': 'Пользователь',
            'status': 'Статус 1С'
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status}`,
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: { name: 20 },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
      showSidebar: false,
      dataSidebar: {},
      userSearchResult: null,
      userSearchOptions: [],
      status: ''
    }
  },
  mounted() {
    this.limitSelected.name = 20
    this.fetchOrders(1, this.limitSelected.name, '')
  },
  methods: {
    toNormalDateTime(myDate) {
      const date = new Date(myDate)
      return new Intl.DateTimeFormat('ru-RU', { dateStyle: 'short', timeStyle: 'short' }).format(date)
    },
    fetchOrders(page = 1, limit, status = '', search = '') {
      this.$store.dispatch('orders/GET_LIST', { page, limit, status, search, client_id: '' })
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          })
        })
    },
    updateLimiter(data) {
      this.limitSelected.name = data.name
      this.$refs.ordersListTable.setLimit(data.name)
      this.fetchOrders(1, data.name)
    },
    clickPaginationCallback(page) {
      this.fetchOrders(page, this.limitSelected.name, this.status)
    },
    returnStart() {
      return (this.pagination.page * this.table.items.length) - (this.table.items.length - 1)
    },
    returnEnd() {
      return this.returnStart() + this.table.items.length - 1
    },
    onRowClick(row) {
      this.$router.push({ name: 'order-detail', params: { id: row.row.id } })
    },
    onSearch(search, loading) {
      loading(true)
      this.searchOrders(loading, search, this)
    },
    searchOrders: debounce((loading, search, vm) => {
      vm.fetchOrders(1, vm.limitSelected.name, vm.status, search)
      loading(false)
    }, 350),
    getLabel(option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          )
        }
        return option[this.label]
      }
      return option
    },
  }
}
</script>
